import React from 'react'
import Notes from './Notes'

const Home = (props) => {
  return (
    <div>
      <Notes showAlert={props.showAlert} setGuestMode={props.setGuestMode}></Notes>
    </div>
  )
}

export default Home